import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { lazy, Suspense } from "react";
import "./index.css";
import './i18n';
import Chatbot from "./components/chatbot/Chatbot";
import Loading from "./components/loading/Loading";

// Lazy Load Components
const Dashboard = lazy(() => import("./routes/dashboard/Dashboard"));
const Inventory = lazy(() => import("./routes/inventory/Inventory"));
const NewInventory = lazy(() => import("./routes/inventory/NewInventory"));
const Dealership = lazy(() => import("./routes/dealership/Dealership"));
const NewDealership = lazy(() => import("./routes/dealership/NewDealership"));
const Login = lazy(() => import("./routes/login/Login"));
const Logout = lazy(() => import("./routes/login/Logout"));
const ReportUpload = lazy(() => import("./routes/report/ReportUpload"));
const Report = lazy(() => import("./routes/report/Report"));
const Video = lazy(() => import("./routes/video/Video"));
const VideoDetails = lazy(() => import("./routes/video/VideoDetails"));
const Editor = lazy(() => import("./routes/video/editor/Editor"));
const Playlist = lazy(() => import("./routes/playlist/Playlist"));
const PlaylistDetails = lazy(() => import("./routes/playlist/PlaylistDetails"));
const Widget = lazy(() => import("./routes/widget/Widget"));
const NewWidget = lazy(() => import("./routes/widget/NewWidget"));
const Tag = lazy(() => import("./routes/settings/Tag"));
const Category = lazy(() => import("./routes/settings/Category"));
const New = lazy(() => import("./routes/settings/New"));
const Account = lazy(() => import("./routes/Account"));

const router = createBrowserRouter([
  { path: "/", element: <Dashboard /> },
  { path: "/login", element: <Login /> },
  { path: "/logout", element: <Logout /> },
  { path: "/dashboard/:id", element: <Dashboard /> },
  { path: "/dealership", element: <Dealership /> },
  { path: "/dealership/new", element: <NewDealership /> },
  { path: "/dealership/:id", element: <NewDealership /> },
  { path: "/inventory", element: <Inventory /> },
  { path: "/inventory/new", element: <NewInventory /> },
  { path: "/inventory/:id", element: <NewInventory /> },
  { path: "/video", element: <Video /> },
  { path: "/video/:id", element: <VideoDetails /> },
  { path: "/video/editor", element: <Editor /> },
  { path: "/playlist", element: <Playlist /> },
  { path: "/playlist/new", element: <PlaylistDetails mode={"new"} /> },
  { path: "/playlist/:id", element: <PlaylistDetails mode={"edit"} /> },
  { path: "/widget", element: <Widget /> },
  { path: "/widget/new", element: <NewWidget /> },
  { path: "/widget/:id", element: <NewWidget /> },
  { path: "/report_upload", element: <ReportUpload /> },
  { path: "/report", element: <Report /> },
  { path: "/tag", element: <Tag /> },
  { path: "/tag/:type", element: <New /> },
  { path: "/category", element: <Category /> },
  { path: "/category/:type", element: <New /> },
  { path: "/account", element: <Account /> },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <Chatbot />
    <Suspense fallback={<Loading visible={true} />}>
      <RouterProvider router={router} />
    </Suspense>
  </>
);
